import { Controller } from "@hotwired/stimulus"
import { nodeIdFromUrl } from "global/javascript/utils"

export default class extends Controller {
  connect() {
    const activeId = nodeIdFromUrl(new URL(window.location.href))
    const activeItem = document.getElementById(activeId)
    if (activeItem) {
      this.dispatch("item", {detail: {element: activeItem, scroll: true}})
    }
  }
}
