import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = { scroll: Boolean }

  connect() {
    this.showTab(this.hash)

    const activeItem = document.getElementById(this.hash.replace("#", ""))
    if (activeItem) {
      const parentPanel = activeItem?.closest("[data-tabs-target=\"panel\"")

      // this means the active element is at the level of tabs so we can start opening parent tabs from here
      if (this.panelTargets.includes(parentPanel)) {
        this._openParents(parentPanel, this.element, this.panelTargets, this.tabTargets)
      }
      this.loadValue && activeItem.scrollIntoView()
    }
  }

  change(event) {
    event.preventDefault()
    this.hash = event.currentTarget.hash
    this.showTab(this.hash)
  }

  showTab(hash) {
    let tab, panel
    const anchoredTab = this.tabTargets.find(tab => tab.getAttribute("name") === hash.slice(1))

    if (anchoredTab) {
      const index = this.tabTargets.indexOf(anchoredTab)
      tab = anchoredTab
      panel = this.panelTargets[index]
    } else {
      panel = this.panelTargets.find(element => !element.getAttribute("aria-selected")) || this.panelTargets[0]
      tab = this.tabTargets.find(element => element.getAttribute("aria-selected")) || this.tabTargets[0]
    }

    this._setActiveTab(panel, tab, this.panelTargets, this.tabTargets, hash !== "")
  }

  _setActiveTab(panel, tab, panels, tabs, scroll = false) {
    tabs.forEach(tab => {
      tab.setAttribute("aria-selected", false)
    })
    panels.forEach(panel => {
      panel.setAttribute("aria-hidden", true)
    })

    tab.setAttribute("aria-selected", true)
    panel.setAttribute("aria-hidden", false)

    if (scroll) {
      tab.scrollIntoView({ block: "nearest" })
    }

    const customEventId = this.element.parentNode.getAttribute("data-custom-event-id")
    if (customEventId) {
      this.dispatch(`change:${customEventId}`, {
        detail: {
          target: tab
        }
      })
    }
  }

  _openParents(panel, tabRoot) {
    const panels = tabRoot.querySelectorAll(":scope > [data-tabs-target=\"panel\"")
    const tabs = tabRoot.querySelectorAll(":scope > .combinator__header [data-tabs-target=\"tab\"")
    const index = Array.from(panels).indexOf(panel)
    const tab = tabs[index]

    this._setActiveTab(panel, tab, panels, tabs)

    const parentTabRoot = tabRoot.parentNode.closest(".combinator")
    if (!parentTabRoot) {
      return false
    }

    const parentPanel = panel.parentNode.closest("[data-tabs-target=\"panel\"")
    return this._openParents(parentPanel, parentTabRoot)
  }

  get index() {
    return parseInt(this.data.get("index", 10) || 0)
  }

  set index(value) {
    this.data.set("index", value)
  }

  get hash() {
    return this.data.get("hash") || window.location.hash
  }

  set hash(value) {
    history.replaceState(null, null, value)
    this.data.set("hash", value)
  }
}
