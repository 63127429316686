import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "current", "activeItem"]

  toggle() {
    const menuIsOpen = this.buttonTarget.getAttribute("aria-expanded") === "true"

    if (menuIsOpen) {
      this.buttonTarget.setAttribute("aria-expanded", "false")
    } else {
      this.buttonTarget.setAttribute("aria-expanded", "true")
      this.scrollToActiveItem()
    }
  }

  scrollToActiveItem() {
    const isScrollable = this.element.dataset.scrollable === "true"
    if (isScrollable && this.hasActiveItemTarget) {
      this.activeItemTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.buttonTarget.setAttribute("aria-expanded", "false")
    }
  }

  change(event) {
    this.currentTarget.textContent = event.currentTarget.textContent
  }
}
