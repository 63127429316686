import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.filledInputs = []

    this.element.querySelectorAll("input, select").forEach(input => {
      this.#checkContent(input)
    })

    this.#setClearState()
  }

  inputChange(event) {
    const input = event.currentTarget
    this.#checkContent(input)
    this.#setClearState()
  }

  clearAll() {
    this.filledInputs = []
    this.element.classList.remove("clearable")
  }

  #checkContent(input) {
    if (input.value) {
      this.filledInputs.push(input)
    } else {
      if (this.filledInputs.includes(input)) {
        this.filledInputs.pop(input)
      }
    }
  }

  #setClearState() {
    this.element.classList.toggle("clearable", this.filledInputs.length)
  }
}
