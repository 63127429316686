import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["authSchemeLabel", "apiKey"]

  connect() {
    this.updateSelectedAuthScheme()
  }

  selectAuthScheme(event) {
    const buttonOrSpanAuthentication = event.currentTarget

    const closestLi = buttonOrSpanAuthentication.closest("li")
    const previousSelected = this.element.querySelector("[aria-selected]")
    if (previousSelected) {
      previousSelected.removeAttribute("aria-selected")
      previousSelected.querySelector("[data-selected-scheme]").dataset.selectedScheme = false
    }
    closestLi.setAttribute("aria-selected", "true")

    buttonOrSpanAuthentication.dataset.selectedScheme = true

    this.updateSelectedAuthScheme()
  }

  updateSelectedAuthScheme() {
    if (this.#selectedAuthenticationScheme() ) {
      const selectedLabel = this.#computedAuthSchemeLabel()
      this.authSchemeLabelTargets.forEach((scheme) => {
        scheme.innerText = selectedLabel
      })
      this.dispatch("updateAuthSchemeDom", { detail: { apiKey: this.apiKeyTarget?.value || "" } })
      this.dispatch("updateAuthSchemeRequest", { detail: { apiKey: this.apiKeyTarget?.value || "" } })
    }
  }

  #selectedAuthenticationScheme() {
    return this.element.querySelector("[data-selected-scheme=true]")
  }

  #computedAuthSchemeLabel() {
    const selectedElement = this.#selectedAuthenticationScheme()
    return selectedElement ? selectedElement?.dataset.schemeLabel : ""
  }
}
