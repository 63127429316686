import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["anchor"]

  change(event) {
    event.preventDefault()
    let tab

    if (typeof event.detail === "object") {
      tab = event.detail.target
    } else {
      if (event.target.classList.contains("tab")) {
        tab = event.target
      } else {
        tab = event.target.closest("a.tab")
      }
    }
    const link = tab.href.split("#")[1]

    if (this.hasAnchorTarget) {
      this.anchorTarget.href = `#${link}`
    }
  }

  setCurrentForm(event) {
    const currentTab = event.currentTarget
    const tabName = currentTab.getAttribute("name")
    const currentPanel = this.element.querySelector(`div#${tabName}`)

    this.element.querySelectorAll("[data-tabs-target=panel]").forEach((panel) => {
      const fields = panel.querySelectorAll("input, select")
      fields.forEach((element)=>{
        element.setAttribute("disabled", "true")
      })
    })

    currentPanel.querySelectorAll("input, select").forEach((element)=>{
      element.removeAttribute("disabled")
    })
    
    this.dispatch("form")
  }
}
