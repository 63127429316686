import { Controller } from "@hotwired/stimulus"
import mermaid from "mermaid"

export default class extends Controller {
  connect() {
    mermaid.initialize({
      // https://mermaid.js.org/config/usage.html#securitylevel
      securityLevel: "strict",
      startOnLoad: false,
    })
    // Run on full document on load
    mermaid.run()

    // Run on rendered turbo frame
    document.addEventListener("turbo:frame-render", this.run.bind(this))
  }

  async run(event) {
    const nodes = event?.target?.querySelectorAll("pre.mermaid")

    if (nodes && nodes.length) {
      await mermaid.run({nodes})
    }
  }
}
