import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "item", "preventClose"]

  toggle(event) {
    event.preventDefault()
    if (this.itemTarget.getAttribute("data-toggle-active") === "true") {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.buttonTarget.setAttribute("aria-pressed", true)
    this.itemTarget.setAttribute("data-toggle-active", true)
    this.dispatch("show")
  }

  hide() {
    this.buttonTarget.setAttribute("aria-pressed", false)
    this.itemTarget.setAttribute("data-toggle-active", false)
    this.dispatch("hide")
  }

  globalHide(event) {
    const target = event.target
    if (this.itemTarget.getAttribute("data-toggle-active") !== "true"
      || this.itemTarget.contains(target)
      || this.buttonTarget == event.target
      || this.#preventsClosing(target)) {
      return false
    }
    this.hide()
  }

  #preventsClosing(item) {
    return this.preventCloseTargets.find(target => target.contains(item))
  }
}
